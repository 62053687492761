import qs from 'qs'

const baseUrl = process.env.GATSBY_SHOP_DIRECTORY_API_URL

export const fetchData = async (path, params) => {
  const url = buildUrl(path, params);
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const json = await response.json();
    if (!json) {
      throw new Error('Empty response received from API');
    }

    return json;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error.message);
    throw error;
  }
};

const buildUrl = (path, params) => {
  const location = `${baseUrl}/${path}`
  const query = qs.stringify(params, {arrayFormat: 'brackets'})
  return [location, query].filter(p => p).join('?')
}

export function decodeJSONApiResponse(response, options = {}) {
  if (!response || typeof response !== 'object') {
    throw new Error('Invalid response: Expected a non-null object');
  }

  const { method } = options;
  const { meta, data: result = null, included } = response;

  if (method === 'delete') {
    return { meta };
  }

  // Handle null or undefined data
  if (result === null) {
    return {
      data: [],
      meta: meta || {},
      ...(included ? { included } : {}),
    };
  }

  if (Array.isArray(result)) {
    return {
      data: result.map((r) =>
        decodeJSONApiResponse({ data: r, included }).data
      ),
      meta,
    };
  }

  const { merchantid: id, primaryCategory: type, attributes, relationships } = result || {};
  const decoded = { data: { id, type, ...attributes }, meta };

  if (relationships) {
    fillRelationships(decoded, relationships, buildIncludedMap(included));
  }

  return decoded;
}

function buildIncludedMap(included){
  const includedMap = {}
  included = included || []
  included.forEach(included => {
    includedMap[`${included.id}.${included.type}`] = {id: included.id, type: included.type, ...included.attributes }
  })
  included.forEach(({id, type, relationships}) => {
    if(relationships && Object.entries(relationships).length){
      const object = includedMap[`${id}.${type}`]
      Object.entries(relationships).forEach(([relationship, {data}]) => {
        if(!data)
          return
        const extractObject = ({id, type}) => includedMap[`${id}.${type}`]
        object[relationship] = Array.isArray(data) ? data.map(extractObject) : extractObject(data)
      })
    }
  })
  return includedMap
}

function fillRelationships(decoded, relationships, included){
  Object.entries(relationships).forEach(([relationship, {data}]) => {
    const extractObject = ({id, type, relationships}) => ({id, type, ...included[`${id}.${type}`]})
    if(data) {
      decoded.data[relationship] = Array.isArray(data) ? data.map(extractObject) : extractObject(data)
    }
  })
  return decoded
}

