import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/header.module.css';
import Search from '../images/search';
import Times from '../images/times';
import { classes } from '../utils/classes';

const Header = ({
                  filter = {},
                  headerText = 'Search, shop, repeat...',
                  headerTextColor = '#000',
                  placeholderText = 'Search...',
                  searchText = '',
                  onFilterChange = () => {},
                  onSearchTextChange = () => {},
                  onClearSearch = () => {},
                  backgroundImage = null,
                  hideOnlineInStoreToggles = false,
                }) => (
  <div
    className={classes(styles.wrapper, hideOnlineInStoreToggles && styles.extraPadding)}
    style={
      backgroundImage?.url
        ? { backgroundImage: `url(${backgroundImage.url})` }
        : {}
    }
  >
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 style={{ color: headerTextColor }}>{headerText || 'Search, shop, repeat...'}</h1>
        <div className={styles.search}>
          <input
            type="search"
            placeholder={placeholderText}
            value={searchText || ''}
            onChange={onSearchTextChange}
          />
          <div className={styles.searchButton}>
            {!!searchText && (
              <button onClick={onClearSearch}>
                <Times />
              </button>
            )}
            <button>
              <Search />
            </button>
          </div>
        </div>
        {
          typeof window !== 'undefined' && !hideOnlineInStoreToggles && (
          <div className={styles.options}>
            <button
              className={classes(
                !filter.online && !filter.inStore && styles.active
              )}
              onClick={() =>
                onFilterChange({ online: undefined, inStore: undefined })
              }
            >
              All
            </button>
            <button
              className={classes(filter.online && styles.active)}
              onClick={() => onFilterChange({ online: true, inStore: undefined })}
            >
              Online
            </button>
            <button
              className={classes(filter.inStore && styles.active)}
              onClick={() => onFilterChange({ online: undefined, inStore: true })}
            >
              In Store
            </button>
          </div>
        )}
      </div>
    </div>
  </div>
);

Header.propTypes = {
  filter: PropTypes.object,
  headerText: PropTypes.string,
  headerTextColor: PropTypes.string,
  placeholderText: PropTypes.string,
  onFilterChange: PropTypes.func,
  onSearchTextChange: PropTypes.func,
  onClearSearch: PropTypes.func,
  backgroundImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  hideOnlineInStoreToggles: PropTypes.bool,
};

export default Header;
