import React, { useState, useEffect } from "react"
import styles from 'src/css/slices/tiles.module.css'
import { getTiles } from 'src/shop_directory/api'
import Tile from 'src/shop_directory/components/tile'
import { classes } from 'src/utilities/classes'
import ArrowLeft from 'src/images/arrow_left'
import ArrowRight from 'src/images/arrow_right'
import LinkButton from 'src/components/link_button'
import DynamicStyle from 'src/components/dynamic_style'
import { optionalThreeColorGradient } from 'src/utilities/color'
import SiteSettingsContext from 'src/components/site_settings_context'

const Tiles = ({ primary: { tile_hover_color_1, tile_hover_color_2, tile_hover_color_3, button_text, button_link } = {}, fields }) => {
  const tileHoverBackground = optionalThreeColorGradient(tile_hover_color_1, tile_hover_color_2, tile_hover_color_3)
  return (
    <div className={styles.wrapper}>
      <DynamicStyle className="tile-hover-gradient" dynamicStyle={{
        '&:before': { background: tileHoverBackground },
        '&:after': { background: tileHoverBackground }
      }} />
      {fields.map((row, i) => <TileRow key={i} {...row} />)}
      {button_text && <div className={styles.buttonSection}>
        <LinkButton to={button_link}>
          {button_text}
        </LinkButton>
      </div>}
    </div>
  )
}

const TileRow = ({ heading, badge_text, disclaimer, category_id, max_tiles, order }) => {
  const [tiles, setTiles] = useState([])
  const [page, setPage] = useState(0)

  useEffect(() => {
    (async () => {
      const { data } = await getTiles({ page: { number: 1, size: max_tiles }, categoryId: category_id, filter: { order }, cached: 'true' })
      setTiles(data)
    })()
  }, [category_id, max_tiles, order])

  const maxPage = Math.ceil(tiles.length / 4) - 1
  const handleMobileScroll = ({ currentTarget: div }) => {
    if (div && div.offsetWidth + div.scrollLeft >= (((page + 1) * 4 - 1) * 0.8 * window.innerWidth)) {
      setPage(page + 1)
    }
  }

  return (
    <SiteSettingsContext.Consumer>
      {siteSettings =>
        <div>
          <div className={styles.heading}>
            <div>
              <h2>{heading}</h2>
              {badge_text && <span>{badge_text}</span>}
            </div>
            <div className={styles.nav}>
              <button onClick={() => setPage(page - 1)} disabled={page <= 0}><ArrowLeft /></button>
              <button onClick={() => setPage(page + 1)} disabled={page >= maxPage}><ArrowRight /></button>
            </div>
          </div>
          <div className={styles.rowWrapper} onScroll={page >= maxPage ? undefined : handleMobileScroll}>
            <div className={classes(styles.rowInner, styles.desktop)}>
              {tiles.slice(page * 4, page * 4 + 4).map((tile, i) => <Tile key={tile.id} tile={tile} loading="lazy" siteSettings={siteSettings} />)}
            </div>
            <div className={classes(styles.rowInner, styles.mobile)}>
              {tiles.slice(0, page * 4 + 4).map((tile, i) => <Tile key={tile.id} tile={tile} loading="lazy" siteSettings={siteSettings} />)}
            </div>
          </div>
          {
            disclaimer &&
            <div className={styles.disclaimerText}>
              {disclaimer}
            </div>
          }
        </div>
      }</SiteSettingsContext.Consumer>
  )
}

export default Tiles
