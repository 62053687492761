import React, { useEffect, useReducer, useState, useRef } from 'react';
import Header from '../components/header';
import Tile from '../components/tile';
import { getCategories, getTiles } from '../api';
import styles from '../styles/shop-directory.module.css';

const initialState = {
  categories: [],
  tiles: [],
  totalCategories: 0,
  totalMerchants: 0,
  pageNumber: 1,
  merchantPageNumber: 1,
};

const LoadingIndicator = () => (
  <div className={styles.loadingWrapper}>
    <svg width="80" height="20" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#8175FD">
      <circle cx="15" cy="15" r="15">
        <animate attributeName="r" from="15" to="15"
                 begin="0s" dur="0.8s"
                 values="15;9;15" calcMode="linear"
                 repeatCount="indefinite" />
        <animate attributeName="fill-opacity" from="1" to="1"
                 begin="0s" dur="0.8s"
                 values="1;.5;1" calcMode="linear"
                 repeatCount="indefinite" />
      </circle>
      <circle cx="60" cy="15" r="9" fillOpacity="0.3">
        <animate attributeName="r" from="9" to="9"
                 begin="0s" dur="0.8s"
                 values="9;15;9" calcMode="linear"
                 repeatCount="indefinite" />
        <animate attributeName="fill-opacity" from="0.5" to="0.5"
                 begin="0s" dur="0.8s"
                 values=".5;1;.5" calcMode="linear"
                 repeatCount="indefinite" />
      </circle>
      <circle cx="105" cy="15" r="15">
        <animate attributeName="r" from="15" to="15"
                 begin="0s" dur="0.8s"
                 values="15;9;15" calcMode="linear"
                 repeatCount="indefinite" />
        <animate attributeName="fill-opacity" from="1" to="1"
                 begin="0s" dur="0.8s"
                 values="1;.5;1" calcMode="linear"
                 repeatCount="indefinite" />
      </circle>
    </svg>
  </div>
)

const ShopDirectory = ({
                         locale,
                         searchBarHeaderText,
                         searchBarPlaceholderText,
                       }) => {
  const [state, dispatch] = useReducer((prev, next) => ({ ...prev, ...next }), initialState)
  const [searchText, setSearchText] = useState("")
  const [debouncedSearchText, setDebouncedSearchText] = useState("")
  const [loading, setLoading] = useState(false)
  const previousTilesRef = useRef(state.tiles)
  const previousCategoriesRef = useRef(state.categories)

  useEffect(() => {
    setLoading(true);
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText)
    }, 800)

    return () => clearTimeout(handler)
  }, [searchText])

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true)
      try {
        const { data, meta } = await getCategories(state.pageNumber, 10)
        dispatch({
          categories: [...previousCategoriesRef.current, ...data],
          totalMerchants: meta.totalCount,
        })
      } finally {
        setLoading(false);
      }
    }

    fetchCategories()
  }, [state.pageNumber])

  useEffect(() => {
    const loadMerchants = async () => {
      setLoading(true)
      try {
        const { data, meta } = await getTiles({
          search: debouncedSearchText,
          pageNumber: state.merchantPageNumber,
          pageSize: 10,
        });

        dispatch({
          tiles: [...previousTilesRef.current, ...data],
          totalMerchants: meta.totalCount,
        });
        previousTilesRef.current = [...previousTilesRef.current, ...data];
      } finally {
        setLoading(false);
      }
    };

    loadMerchants();
  }, [debouncedSearchText, state.merchantPageNumber]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    previousTilesRef.current = [];
    dispatch({
      totalMerchants: 0,
      merchantPageNumber: 1,
      tiles: [],
      filter: {
        searchText,
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <Header
        headerText={searchBarHeaderText}
        placeholderText={searchBarPlaceholderText}
        onSearchTextChange={handleSearch}
        onClearSearch={() => setSearchText('')}
        searchText={searchText}
        hideOnlineInStoreToggles={true}
      />
      <div className={styles.tiles}>
        {state.tiles.map((tile, idx) => (
          <Tile key={idx} tile={tile} />
        ))}
      </div>
      {loading && <LoadingIndicator />}
      {!loading && state.tiles.length < state.totalMerchants && (
        <button className={styles.loadMore} onClick={() => dispatch({ merchantPageNumber: state.merchantPageNumber + 1 })}>
          Load More
        </button>
      )}
    </div>
  );
};

export default ShopDirectory;
