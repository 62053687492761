import { fetchData } from '../utils/JSONApiUtils';

export const getCategories = async (pageNumber = 1, pageSize = 10) => {
  const options = {
    page: { size: pageSize, number: pageNumber },
  };
  return fetchData('categories', options);
};

export const getTiles = async ({ categoryId, search = '', pageNumber = 1, pageSize = 10 }) => {
  const options = {
    pageSize,
    pageNumber,
    search,
    categoryId,
  };
  global.dataLayer.push({ event: 'ShopHereSearch', query: options });
  return fetchData('merchants', options);
};
